import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import {
  ORGANIZATIONS_HOME_PATH,
  ORGANIZATIONS_DETAILS_PATH,
  ORGANIZATIONS_EDIT_PATH,
  SITES_DETAILS_PATH,
  SITES_EDIT_PATH,
  SITES_HOME_PATH,
  USERS_HOME_PATH
} from '../paths';

export const configurationRoutes = [
  {
    path: USERS_HOME_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/users/UsersHomePage'))
  },
  {
    path: SITES_HOME_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/sites/SitesHomePage'))
  },
  {
    path: SITES_DETAILS_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/sites/SiteDetailsPage'))
  },
  {
    path: SITES_EDIT_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/sites/SiteEditPage'))
  },
  {
    path: ORGANIZATIONS_HOME_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/organizations/OrganizationsHomePage'))
  },
  {
    path: ORGANIZATIONS_DETAILS_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/organizations/OrganizationDetailsPage'))
  },
  {
    path: ORGANIZATIONS_EDIT_PATH,
    lazy: () => lazyWithRetry(() => import('eficia/views/organizations/OrganizationEditPage'))
  }
];
