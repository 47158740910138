import axiosConfig from './config/axiosConfig';
import { USER_API } from './config/constants';

export default function usersApi() {
  return {
    async fetch(params) {
      const query = await axiosConfig.post(`${USER_API}/get-user-list`, params);
      return query;
    },
    async loginAs(params) {
      const query = await axiosConfig.post(`${USER_API}/login-as`, params);
      return query;
    }
  };
}
